<template>
  <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.92572 14.9955V8.07463H0.0957031V5.5828H1.92572V3.45445C1.92572 1.78197 3.00671 0.246094 5.49755 0.246094C6.50606 0.246094 7.2518 0.342776 7.2518 0.342776L7.19304 2.66972C7.19304 2.66972 6.4325 2.66232 5.60257 2.66232C4.70433 2.66232 4.56042 3.07626 4.56042 3.7633V5.5828H7.26444L7.14678 8.07463H4.56042V14.9955H1.92572Z" fill="#475661"/>
  </svg>

</template>

<script>
export default {
  name: "FacebookIcon"
}
</script>

<style scoped>

</style>