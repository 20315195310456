<template>

  <div class="mainDiv tw-mb-4">
    <div v-if="companyFaqs.length === 0 && !isLoading ">
      <CompanyProfileEmptyState>
<!--        <img alt="No FAQs" src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635339414/semicolonAfrica/Frame_48096393_s9eufl.png"/>-->
        <FaqIcon/>
        <h2 class="tw-pt-8 no-faq-text">No FAQs</h2>
      </CompanyProfileEmptyState>
    </div>
  <div v-else class="faqCard">
    <v-expansion-panels flat accordion>
      <v-expansion-panel class="panel"
          v-for="(item, index) in companyFaqs" :key="index"
      >
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between">
            <div class="question">
              {{ item.question }}
            </div>
          </div>
          </v-expansion-panel-header>
        <v-expansion-panel-content class="answer">
          {{ item.answer }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CompanyProfileEmptyState from "@/components/viewCompanyProfile/reuseables/CompanyProfileEmptyState";
import FaqIcon from "@/components/viewCompanyProfile/reuseables/FaqIcon";

export default {
name: "FAQs",
  components: {FaqIcon, CompanyProfileEmptyState},
  computed:{
  ...mapGetters("companyProfile",["companyFaqs", "isLoading"])
  }
}
</script>

<style scoped lang="scss">
.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
span{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}
.mainDiv{
  max-width: 630px;
}
.question{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}
.answer{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.panel{
  border-bottom: 1px solid rgba(208, 220, 228, .3);
}
.faqCard {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
@media (max-width: 640px) {
  max-height: 35vh;
}
}

.faqCard::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.faqCard::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}
.no-faq-text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}
</style>