<template>
  <svg width="127" height="140" viewBox="0 0 127 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_37566_216050)">
      <path d="M6.35283 30.123C5.32954 24.076 9.40212 18.3443 15.4492 17.321L92.0936 4.35116C98.1407 3.32787 103.872 7.40046 104.896 13.4475L120.645 106.516C121.668 112.563 117.595 118.294 111.548 119.318L34.9039 132.288C28.8569 133.311 23.1252 129.238 22.1019 123.191L6.35283 30.123Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter1_d_37566_216050)">
      <path d="M120.647 30.123C121.67 24.076 117.598 18.3443 111.551 17.321L34.9064 4.35116C28.8593 3.32787 23.1277 7.40046 22.1044 13.4475L6.3553 106.516C5.33201 112.563 9.40459 118.294 15.4517 119.318L92.0961 132.288C98.1431 133.311 103.875 129.238 104.898 123.191L120.647 30.123Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter2_d_37566_216050)">
      <path d="M13.5273 21.1263C13.5273 14.9933 18.4992 10.0215 24.6322 10.0215H102.366C108.499 10.0215 113.471 14.9933 113.471 21.1263V115.518C113.471 121.651 108.499 126.623 102.366 126.623H24.6322C18.4991 126.623 13.5273 121.651 13.5273 115.518V21.1263Z" fill="#F7FCFF"/>
    </g>
    <path d="M62.1121 57.2168H52.3954C51.6591 57.2168 50.9529 57.5093 50.4323 58.0299C49.9116 58.5506 49.6191 59.2567 49.6191 59.993V79.4265C49.6191 80.1628 49.9116 80.869 50.4323 81.3896C50.9529 81.9102 51.6591 82.2027 52.3954 82.2027H71.8289C72.5652 82.2027 73.2713 81.9102 73.7919 81.3896C74.3126 80.869 74.6051 80.1628 74.6051 79.4265V69.7098" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M72.5244 55.1339C73.0766 54.5817 73.8255 54.2715 74.6065 54.2715C75.3875 54.2715 76.1365 54.5817 76.6887 55.1339C77.2409 55.6862 77.5511 56.4351 77.5511 57.2161C77.5511 57.9971 77.2409 58.746 76.6887 59.2983L63.5016 72.4853L57.9492 73.8734L59.3373 68.321L72.5244 55.1339Z" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <filter id="filter0_d_37566_216050" x="0.642881" y="0.029036" width="125.712" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216050"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216050" result="shape"/>
      </filter>
      <filter id="filter1_d_37566_216050" x="0.644835" y="0.029036" width="125.712" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216050"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216050" result="shape"/>
      </filter>
      <filter id="filter2_d_37566_216050" x="7.97491" y="5.85716" width="111.048" height="127.706" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216050"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216050" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "ReviewIcon"
}
</script>

<style scoped>

</style>