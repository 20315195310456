<template>
  <div>
    <div v-if="!showWrite">
      <DisplayReviews @change="writeReview"/>
    </div>
    <div v-else>
      <WriteReviews @change="submittedReview"/>
    </div>

  </div>
</template>

<script>
import DisplayReviews from "@/components/viewCompanyProfile/components/DisplayReviews";
import WriteReviews from "@/components/viewCompanyProfile/components/WriteReviews";
export default {
  name: "Reviews",
  components: {WriteReviews, DisplayReviews},
  data(){
    return{
      showWrite:false,
    }
  },
  methods:{
    submittedReview(){
      this.showWrite = false
    },
    writeReview(){
      this.$router.push({query: {site_name: this.$route.query.site_name, write: 'writeReview'}})
      this.showWrite = true
    }
  }
}
</script>

<style scoped>

</style>