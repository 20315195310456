<template>
  <section class="mediaModal">
  <div v-if="companyDescriptionAndMedia.media_gallery.length === 0 && !isLoading ">
<!--  <div v-if="images.length === 0 && !isLoading ">-->
    <div style="max-width: 630px">
    <CompanyProfileEmptyState>
<!--      <img alt="No Awards" src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635357625/semicolonAfrica/Frame_48096393_kmnekg.png"/>-->
      <MediaIcon/>
      <h2 class="tw-pt-8 no-award-text">No photos uploaded yet</h2>
    </CompanyProfileEmptyState>
    </div>
  </div>
  <div v-else>
    <div class="line tw-my-4">
    </div>
    <div class="mediaCard">
      <v-row>
        <v-col
            cols="12"
            md="3"
            sm="6"
            xs="12"
            v-for="(image, index) in companyDescriptionAndMedia.media_gallery"
            :key="index"
        >
          <v-img  @click="[openModal(image)]" class="img"  :src="image"></v-img>

        </v-col>
      </v-row>
      <EachMediaModal  @closeModal="closeDialog" :item="imageInView" :dialog="dialog">
      </EachMediaModal>
    </div>
  </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import CompanyProfileEmptyState from "@/components/viewCompanyProfile/reuseables/CompanyProfileEmptyState";
import EachMediaModal from "@/components/viewCompanyProfile/components/EachMediaModal";
import MediaIcon from "@/components/viewCompanyProfile/reuseables/MediaIcon";

export default {
name: "Media",
  components: {MediaIcon, EachMediaModal, CompanyProfileEmptyState},
  computed:{
  ...mapGetters("companyProfile", ["companyDescriptionAndMedia", "isLoading"])
  },
  data(){
  return{
    showDragDiv: true,
    file:"",
    dialog: false,
    imageInView: '',
    images:["https://res.cloudinary.com/semicolon-dev/image/upload/v1634305822/semicolonAfrica/joinSwitModalImage.png",
       "https://res.cloudinary.com/semicolon-dev/image/upload/v1634293220/semicolonAfrica/partnership_u0b50g.png",
      "https://res.cloudinary.com/semicolon-dev/image/upload/v1634293221/semicolonAfrica/hireTalent_awzzpb.png",
      "https://res.cloudinary.com/semicolon-dev/image/upload/v1634304494/semicolonAfrica/mentorship.png",
      "https://res.cloudinary.com/semicolon-dev/image/upload/v1634293219/semicolonAfrica/corporateTraining_lptpz9.png",
      "https://res.cloudinary.com/semicolon-dev/image/upload/v1634304494/semicolonAfrica/mentorship.png",
      "https://res.cloudinary.com/semicolon-dev/image/upload/v1634304495/semicolonAfrica/codewars.png",
     "https://res.cloudinary.com/semicolon-dev/image/upload/v1634304494/semicolonAfrica/mentorship.png"
    ]
  }
  },
  methods:{
    closeDialog(){
      this.dialog=false
    },
    openModal(image) {
      this.imageInView = image
      this.dialog = true
    },
  }
}
</script>

<style scoped lang="scss">
.img{
  border-radius: 6px;
  height: 210px;
}
span{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}
.line{
  border-bottom: 1px solid rgba(208, 220, 228, .3);
}
.mediaCard {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.mediaCard::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.mediaCard::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}
</style>