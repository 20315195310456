<template>
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.26841 14.9955V5.04409H0.960774V14.9955H4.26841ZM2.61502 3.68458C3.76845 3.68458 4.4864 2.92043 4.4864 1.9655C4.46491 0.989034 3.76849 0.246094 2.63691 0.246094C1.5055 0.246094 0.765625 0.989048 0.765625 1.9655C0.765625 2.92048 1.4834 3.68458 2.59342 3.68458H2.61491H2.61502ZM6.09917 14.9955H9.4068V9.43813C9.4068 9.14071 9.42829 8.8436 9.51564 8.63099C9.75476 8.03675 10.299 7.42129 11.2127 7.42129C12.4096 7.42129 12.8884 8.33386 12.8884 9.67163V14.9954H16.1958V9.28937C16.1958 6.2327 14.564 4.81046 12.3878 4.81046C10.6035 4.81046 9.82004 5.80784 9.3848 6.48715H9.40688V5.04388H6.09924C6.14265 5.97767 6.09924 14.9953 6.09924 14.9953L6.09917 14.9955Z" fill="#475661"/>
  </svg>

</template>

<script>
export default {
  name: "LinkedInIcon"
}
</script>

<style scoped>

</style>