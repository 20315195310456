<template>
  <div >
    <v-dialog v-model="dialog" max-width="750" overlay-opacity="0.94" @click:outside="closeDialog">
      <v-img contain max-height="600px" alt="company-image"
             :src="item">
        <div class="d-flex justify-end">
          <div @click="closeDialog" class="tw-p-1.5 tw-mt-2 tw-mr-2 delete">
            <vue-feather style="color: white" size="30" type="x"/>
          </div>
        </div>
      </v-img>
    </v-dialog>
  </div>
</template>

<script>
export default {
name: "EachMediaModal",
  data() {
    return {
      loading: false
    }
  },
  props:{
    item: String,
    dialog: {
      type: Boolean,
      default: false
    },
  },
  methods:{
    closeDialog() {
      this.$emit('closeModal')
    },
  }
}
</script>

<style scoped>

</style>