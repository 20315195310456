<template>
  <svg width="126" height="140" viewBox="0 0 126 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_37566_216062)">
      <path d="M5.85283 30.123C4.82954 24.076 8.90212 18.3443 14.9492 17.321L91.5936 4.35116C97.6407 3.32787 103.372 7.40046 104.396 13.4475L120.145 106.516C121.168 112.563 117.095 118.294 111.048 119.318L34.4039 132.288C28.3569 133.311 22.6252 129.238 21.6019 123.191L5.85283 30.123Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter1_d_37566_216062)">
      <path d="M120.147 30.123C121.17 24.076 117.098 18.3443 111.051 17.321L34.4064 4.35116C28.3593 3.32787 22.6277 7.40046 21.6044 13.4475L5.8553 106.516C4.83201 112.563 8.90459 118.294 14.9517 119.318L91.5961 132.288C97.6431 133.311 103.375 129.238 104.398 123.191L120.147 30.123Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter2_d_37566_216062)">
      <path d="M13.0283 21.1263C13.0283 14.9933 18.0001 10.0215 24.1332 10.0215H101.867C108 10.0215 112.972 14.9933 112.972 21.1263V115.518C112.972 121.651 108 126.623 101.867 126.623H24.1332C18.0001 126.623 13.0283 121.651 13.0283 115.518V21.1263Z" fill="#F7FCFF"/>
    </g>
    <path d="M74.1061 61.3809H51.8963C50.3631 61.3809 49.1201 62.6238 49.1201 64.1571V78.0382C49.1201 79.5714 50.3631 80.8144 51.8963 80.8144H74.1061C75.6393 80.8144 76.8823 79.5714 76.8823 78.0382V64.1571C76.8823 62.6238 75.6393 61.3809 74.1061 61.3809Z" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M68.5531 80.8141V58.6043C68.5531 57.868 68.2606 57.1619 67.74 56.6413C67.2193 56.1206 66.5132 55.8281 65.7769 55.8281H60.2245C59.4882 55.8281 58.782 56.1206 58.2614 56.6413C57.7407 57.1619 57.4482 57.868 57.4482 58.6043V80.8141" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <filter id="filter0_d_37566_216062" x="0.142881" y="0.029036" width="125.711" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216062"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216062" result="shape"/>
      </filter>
      <filter id="filter1_d_37566_216062" x="0.145811" y="0.029036" width="125.711" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216062"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216062" result="shape"/>
      </filter>
      <filter id="filter2_d_37566_216062" x="7.47589" y="5.85716" width="111.048" height="127.706" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216062"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216062" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "JobsIcon"
}
</script>

<style scoped>

</style>