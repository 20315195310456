<template>
<div class="mainDiv tw-mb-4">
    <div v-if="companyAwards.length === 0 && !isLoading ">
      <CompanyProfileEmptyState>
<!--        <img alt="No Awards" src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635261336/semicolonAfrica/Frame_48096393_r0tby3.png"/>-->
        <AwardIcon/>
        <h2 class="tw-pt-8 no-award-text">No Awards</h2>
      </CompanyProfileEmptyState>
    </div>

    <div v-else >
      <div style="cursor: pointer" v-for="(item, index) in companyAwards" :key="index">
        <v-card flat class="d-flex card tw-py-4 justify-space-between">
          <div class="d-flex tw-items-start ">
            <img v-if="item.image" alt="award-image" class="rounded avatar img" width="60" height="60" :src="item.image">
            <v-avatar v-if="!item.image" class="avatar tile rounded" style="border-radius: 3px" size="60">
              <h3>{{initials(item.title)}}</h3></v-avatar>
            <div class="tw-ml-3">
              <h4 class="text-capitalize">{{ item.title }}</h4>
              <h5 class="tw-pt-1.5 text-capitalize">{{ item.organiser }} - {{item.year_awarded}}</h5>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyProfileEmptyState from "@/components/viewCompanyProfile/reuseables/CompanyProfileEmptyState";
import AwardIcon from "@/components/viewCompanyProfile/reuseables/AwardIcon";
import {mapGetters} from "vuex";

export default {
name: "Awards",
  components: {AwardIcon, CompanyProfileEmptyState},
  methods:{
    initials(item) {
      const firstName = item.split(' ');
      const initials = firstName.shift().charAt(0);
      return initials.toUpperCase();
    },
  },
  computed:{
    ...mapGetters("companyProfile",["companyAwards", "isLoading"])
  }
}
</script>

<style scoped>
.img{
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
span{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}
.mainDiv{
  max-width: 630px;
}
.avatar{
  border: 2.14286px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4.28571px;
}
.tile{
  background: #8F47F4;
}
.card{
  border-bottom: 1px solid rgba(208, 220, 228, .3);
}
h4{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
h5{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
h3{
  font-style: normal;
  font-weight: bold;
  font-size: 25.7143px;
  line-height: 26px;
  color: #FFFFFF;
}
.menu >>> .theme--light.v-divider{
  border-color: rgba(251, 251, 251, 1);
}
.no-award-text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}
</style>