<template>
  <div>
    <div v-if="companyJobs.results.length === 0 && !isLoading ">
      <CompanyProfileEmptyState>
<!--        <img alt="No Awards" src="https://res.cloudinary.com/semicolon-dev/image/upload/v1635871254/semicolonAfrica/Frame_48096393_xln7o8.png"/>-->
        <JobsIcon/>
        <h2 class="tw-pt-8 no-award-text">No jobs uploaded yet</h2>
      </CompanyProfileEmptyState>
    </div>

    <div v-else>
    <div class="d-flex tw-ml-4 tw-mt-5 tw-mb-2  align-center">
      <h5>Open ({{open }})</h5>
      <h5 class="tw-ml-3">Closed ({{close}})</h5>
    </div>
    <div class="line tw-ml-4 tw-mb-1">
    </div>
    <div >
        <div class="card-table">
          <v-data-table
              :headers="headers"
              :search="search"
              hide-default-header
              :items-per-page="3"
              :page.sync="page"
              :items="companyJobs.results"
              hide-default-footer
              :no-data-text="noData"
              @page-count="pageCount = $event"
              height="45vh"
              class="mt-4 mx-1 table"
          >
            <template v-slot:[`item`]="{ item }">
              <div class="lg:tw-mx-4 my-3 pa-5 jobCard mb-5" >

                <div class="d-flex align-center justify-space-between">
                  <h3 class="text-capitalize">{{ item.title }}</h3>
                  <div @click="saveJob(item)" style="cursor: pointer" :style="{background: item.is_saved ?'#F7FCFF':'transparent', borderRadius: '4px'}" class="d-flex align-center justify-center pa-2">
                    <vue-feather size="18" :fill="item.is_saved?'#008EEF':'transparent'" :stroke="item.is_saved ?'#008EEF':'#9CABB5'" type="bookmark"/>
                    <h4 class="ml-1" :style="{color: item.is_saved?'#008EEF':'#9CABB5'}">{{ item.is_saved? 'Saved': 'Save' }}</h4>
                  </div>
                </div>

                <div class="d-flex align-center justify-space-between">
                  <div>
                    <span class="text-capitalize">{{ item.company_name }}</span>
                    <v-avatar class="mx-2" size="4" color="#8F47F4"></v-avatar>
                    <span class="text-capitalize">{{ item.type }}</span>
                  </div>
                  <div>
                    <span class="date">{{ processedDate(item.created_at) }}</span>
                  </div>
                </div>



              </div>
            </template>
          </v-data-table>

        </div>

      <v-pagination
          v-model="page"
          color="#008EEF"
          :length="pageCount"
          :total-visible="5"
      ></v-pagination>

      </div>
      <div>
      </div>
    </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {saveAJob} from "@/services/api/APIService"
import CompanyProfileEmptyState from "@/components/viewCompanyProfile/reuseables/CompanyProfileEmptyState";
import JobsIcon from "@/components/viewCompanyProfile/reuseables/JobsIcon";
export default {
name: "AllJobs",
  components: {JobsIcon, CompanyProfileEmptyState},
  computed:{
    ...mapGetters("companyProfile",["companyJobs", "isLoading"]),
    // eslint-disable-next-line vue/return-in-computed-property
    open(){
      let newArray = this.companyJobs.results
      console.log(newArray)
      if(newArray){
        let openJobs = newArray.filter(item => item.is_published === true)
        return openJobs.length
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    close(){
      let newArray = this.companyJobs.results
      console.log(newArray)
      if(newArray){
        let closedJobs = newArray.filter(item => item.is_closed === true)
        return closedJobs.length
      }
    }
  },
  data(){
    return{
      loading: false,
      search: "",
      noData: 'You don’t have any job listing ',
      filteredResult : [],
      page: 1,
      pageCount: 0,
      openJobs: "",
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "title"
        },
        {text: " ", value: "level"},
        {text: " ", value: "status"},
        {text: "", value: "state"},
        {text: "", value: "city"},

      ]
    }
  },
  methods: {
    processedDate(createdDate) {
      if (!createdDate) return;
      let day;
      let date, now
      day = createdDate.split("T")[0]
      let d1 = date = new Date(day);
      let d2 = now = new Date();
      if (d2.getTime() < d1.getTime()) {
        d1 = now;
        d2 = date;
      }
      let yd = d1.getYear();
      let yn = d2.getYear();
      let years = yn - yd;
      let md = d1.getMonth();
      let mn = d2.getMonth();
      let months = mn - md;
      if (months < 0) {
        years--;
        months = 12 - md + mn;
      }
      let dd = d1.getDate();
      let dn = d2.getDate();
      let days = dn - dd;
      if (days < 0) {
        months--;
        // figure out how many days there are in the last month
        d2.setMonth(mn, 0);
        days = d2.getDate() - dd + dn;
      }
      let weeks = Math.floor(days / 7);
      days = days % 7;
      if (years > 0) return years + 'year' +  (years > 1 ? "s" : "") + " " + "ago"
      if (months > 0) return months + ' month' +  (months > 1 ? "s" : "") + " " + "ago"
      if (weeks > 0) return weeks + ' week' +  (weeks > 1 ? "s" : "") + " " + "ago"
      if(days > 0) return  days + ' day' +  (days > 1 ? "s" : "")  + " " + "ago" ;
      return "Created Today";

    },
    saveJob(item){
      let user = JSON.parse(sessionStorage.getItem('userData'))
      console.log(item.id, user.email)
      saveAJob({email: user.email, job_id: item.id}).then(
          res=> {console.log(res)
          this.$store.dispatch("companyProfile/getAllCompanyJobsAsATalent",{site_name: this.$route.query.site_name, email:user.email})
          }
      ).catch(err=>{console.log(err)})
    },
    // eslint-disable-next-line vue/no-dupe-keys


  },
  // updated() {
  // this.open()
  // }

}
</script>

<style scoped>
h3{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #1E323F;
}
h4{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.jobCard{
  background: #FFFFFF;
  border: 1px solid #F7FCFF;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 8px 16px #F0F9FF ;
  /*cursor: pointer;*/
}

.table >>> ::-webkit-scrollbar {
  width: 3px;
}
.table >>> ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

.table >>> ::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 5px;
}
.card-table ::v-deep .v-data-footer{
  border: #FFFFFF;
}
.card-table ::v-deep .theme--light.v-data-table .v-data-footer{
  border: #FFFFFF;
}
span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.date{
  color: #4F4F4F;
}
h5{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #9CABB5;
}
.line{
  border-bottom: 1px solid rgba(208, 220, 228, .3);
}
</style>