<template>
  <div>
    <div class=" tw-flex px-4 align-center">
      <vue-feather type="arrow-left" style="cursor: pointer" @click="goBack"/>

      <span style="cursor: pointer" @click="goBack">Back</span>
    </div>
    <div class="d-flex justify-center flex-wrap flex-md-nowrap wrapper lg:px-5 mx-auto">
      <div class=" lg:tw-hidden md:tw-hidden px-4 tw-mt-6 mb-5 ">

        <v-card style="background-color: #F6FCFF; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); border-radius: 12px"
                class="organization__info tw-px-2 tw-py-3"
        >
          <div class="tw-flex align-center" @click="showGuides=!showGuides">
            <svg class="mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.9987 15.1224C11.6806 15.1224 14.6654 12.1376 14.6654 8.45573C14.6654 4.77383 11.6806 1.78906 7.9987 1.78906C4.3168 1.78906 1.33203 4.77383 1.33203 8.45573C1.33203 12.1376 4.3168 15.1224 7.9987 15.1224Z"
                  fill="#008EEF"/>
              <path d="M8 11.1237V8.45703" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M8 5.78906H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <span class="guidesTitle">              Guidelines for reviews and ratings</span>
          </div>
          <v-card-text v-if="showGuides">
            <div class="tw-py-1" v-for="guide in guideLines" :key="guide.id">
              <span class="guidesFont">{{ guide.id }}. {{ guide.content }}</span>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <validation-observer class="flex-grow-1" ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(openModal)">
          <v-card flat max-width="700" class=" mr-0 mr-md-16 mb-10 ">
            <v-card-title class="card__text-description px-4  titleFont">Write a Review</v-card-title>
            <v-card-text class="ratingClass px-4">
              <span class="spanText">Select a Star to Rate</span>
              <validation-provider
                  v-slot="{ errors }"
                  name="rating"
                  rules="required">
                <v-rating
                    v-model="user.rating"
                    empty-icon="mdi-star-outline"
                    full-icon="mdi-star"
                    background-color=""
                    color="#FECB54"
                    size="30"
                    class="pb-4 ml-n2"
                    large
                ></v-rating>
                <span class="err">{{ errors[0] }}</span>
              </validation-provider>
              <div class="tw-my-4">
                <small>Job Title</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Job Title"
                    rules="required">
                  <v-combobox
                      v-model="user.job_title"
                      solo
                      hide-details
                      :items="jobTitle"
                      placeholder="Please select your job title"
                  />
                  <span class="err">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="tw-my-4">
                <small>Job Type</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Job Type"
                    rules="required">
                  <v-combobox
                      v-model="user.job_type"
                      solo
                      hide-details
                      :items="jobType"
                      placeholder="Please select your job type"
                  />
                  <span class="err">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="tw-my-4">
                <v-checkbox
                    v-model="user.currently_work_here"
                    solo
                    hide-details
                    color="purple"
                    label="I currently work here"

                />
              </div>

              <div class="tw-my-4">

                <small> Title</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required|max:50">
                  <v-text-field
                      v-model="user.title"
                      solo
                      hide-details
                      placeholder="Title your review (50 Words)"

                  />
                  <span class="err">{{ errors[0] }}</span>
                </validation-provider>
              </div>


              <div class="tw-my-4">
                <small> Pros</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Pros"
                    rules="required|max:150">
                  <v-textarea
                      v-model="user.pros"
                      solo
                      rows="3"
                      hide-details
                      placeholder="List the Pros (150 words)"
                  />
                  <span class="err">{{ errors[0] }}</span>
                </validation-provider>
              </div>


              <div class="tw-my-4">
                <small> Cons</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Cons"
                    rules="required|max:150">
                  <v-textarea
                      v-model="user.cons"
                      solo
                      rows="3"
                      hide-details
                      placeholder="List the Cons (150 words)"
                  />
                  <span class="err">{{ errors[0] }}</span>
                </validation-provider>
              </div>


              <div class="tw-my-4">
                <small> Suggestions to Management/Recruiter</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Suggestion"
                    rules="required|max:150">
                  <v-textarea
                      v-model="user.suggestion"
                      solo
                      rows="2"
                      hide-details
                      placeholder="Input your suggestions 150 words"
                  />
                  <span class="err">{{ errors[0] }}</span>
                </validation-provider>
              </div>


              <div class="tw-mt-3">
                <validation-provider
                    v-slot="{ errors }"
                    name="Terms & Condition"
                    rules="required">

                  <div class="tw-flex tw-items-center">
                    <v-checkbox color="purple" v-model="user.accepted_terms"/>
                    <span class="agree">
                  I agree to
                  <span class="terms">
                    terms & conditions
                  </span>
        </span></div>

                  <span class="err">{{ errors[0] }}</span>
                </validation-provider>
              </div>

            </v-card-text>
            <v-card-actions class="tw-flex tw-justify-end px-4 ">
              <div>
                <BaseButton class="tw-mr-3" :outlined="true" @click="cancel" button-text="Cancel"/>
              </div>
              <div>
                <BaseButton button-text="Submit" :loading="loading" block :disabled="!user.accepted_terms" type="submit" depressed/>
              </div>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
      <ConfirmReviewModal
          :dialog="dialog"
          @close="dialog = false"
          @click="submit"
      />

      <div v-if="!$vuetify.breakpoint.mobile" class="tw-position-fixed pb-16 mb-5 lg:tw-pt-10">

        <v-card style="background-color: #F6FCFF; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); border-radius: 12px"
                class="organization__info tw-px-3 tw-py-3" width="100%"
                max-width="320"
                min-width="320">
          <v-card-title>
            <svg class="mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.9987 15.1224C11.6806 15.1224 14.6654 12.1376 14.6654 8.45573C14.6654 4.77383 11.6806 1.78906 7.9987 1.78906C4.3168 1.78906 1.33203 4.77383 1.33203 8.45573C1.33203 12.1376 4.3168 15.1224 7.9987 15.1224Z"
                  fill="#008EEF"/>
              <path d="M8 11.1237V8.45703" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M8 5.78906H8.00667" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            <span class="guidesTitle">              Guidelines for reviews and ratings</span>
          </v-card-title>
          <v-card-text>
            <div class="tw-px-5 tw-py-1" v-for="guide in guideLines" :key="guide.id">
              <span class="guidesFont">{{ guide.id }}. {{ guide.content }}</span>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import ConfirmReviewModal from "@/components/dashboard/ConfirmReviewModal";
import {sendReview} from "@/services/api/APIService";

export default {
  name: "WriteReviews",
  components: {ConfirmReviewModal, BaseButton},
  data() {
    return {
      loading: false,
      dialog: false,
      showGuides: false,
      guideLines: [
        {
          id: 1,
          content: 'Fill in your work details and ensure they are correct. '
        },
        {
          id: 2,
          content: 'Fill in your work details and ensure they are correct. '
        },
        {
          id: 3,
          content: 'Fill in your work details and ensure they are correct. '
        },
        {
          id: 4,
          content: 'Fill in your work details and ensure they are correct. '
        },
        {
          id: 5,
          content: 'Fill in your work details and ensure they are correct. '
        },
      ],
      user: {
        rating: 0,
        job_title: '',
        title: '',
        job_type: '',
        currently_work_here: false,
        pros: '',
        cons: '',
        suggestion: '',
        accepted_terms: false,

      }
    }
  },
  computed: {
    jobType() {
      return ['Full-Time', 'Part-Time', 'Contract', 'Flexible']
    },
    jobTitle() {
      return ['Jobs', 'People']
    },
    // userDetail(){
    //   return JSON.parse(sessionStorage.getItem('userProfile'))
    // }
  },
  methods: {
    goBack() {
      this.$emit('change')
      this.$router.back()
    },
    async submit() {
      this.loading = true;
      this.user.company = JSON.parse(sessionStorage.getItem('companyId'))
      console.log(this.user)
      if (this.user.rating === 0) {
        this.$displaySnackbar({
          message: 'Kindly select a star rating',
          success: false
        })
        this.loading= false
        return
      }
      await sendReview(this.user).then(res => {
        console.log(res)
        this.$store.commit('companyProfile/set_new_review', res.data)
        this.$displaySnackbar({
          message: 'Review Submitted',
          success: true
        })
        this.$emit('change')
        this.$router.back()
      })
          .catch(err => {
            // let errorKey = Object.keys(err.response.data)
            console.log(err)
            this.$displaySnackbar({
              message: err.response.data.detail,
              success: false
            })
          })
          .finally(() => this.loading = false)

    },
    openModal() {
      this.dialog = true
    },
    cancel() {
      this.user.job_type = '';
      this.user.job_title = '';
      this.user.title = '';
      this.user.pros = '';
      this.user.suggestion = '';
      this.user.cons = '';
      this.user.rating = 0;
      this.user.accepted_terms = false;
      this.user.currently_work_here = false
    }
  }
}
</script>
<style scoped>
.err {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;
}

small {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 4px !important;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

.agree {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #696F79;
  margin-left: 3px;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

.terms {
  color: #008EEF;
  cursor: pointer;
}

.titleFont {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #001343;
}

.spanText {
  ont-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #333333;
}

.guidesFont {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1E323F;

}

.guidesTitle {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
}
</style>