<template>
<div class="tw-mt-10 mainDiv">
<!--  DESCRIPTION VIEW-->

    <div class="d-flex align-center justify-space-between">
      <h3>About</h3>
    </div>
    <div class="tw-mt-3">
      <h5>{{companyDescriptionAndMedia.company_overview}}</h5>
    </div>
  <div v-if="companyDescriptionAndMedia.company_vision && companyDescriptionAndMedia.company_mission !== ''"
       class="tw-mt-10 tw-flex tw-flex-col lg:tw-flex-row md:tw-justify-between ">
    <div class="md:tw-mr-4">
      <h4 class="tw-mb-4">Vision</h4>
      <h5 class="md:tw-w-300">{{companyDescriptionAndMedia.company_vision}}</h5>
    </div>
    <div class=" tw-mt-8 lg:tw-mt-0 ">
      <h4 class="tw-mb-4">Mission</h4>
      <h5 class="md:tw-w-300">{{companyDescriptionAndMedia.company_mission}}</h5>
    </div>
  </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Description",
  computed:{
    ...mapGetters("companyProfile",["companyDescriptionAndMedia"])
  }
}
</script>

<style scoped>
.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
btnDiv ::v-deep .theme--light.v-btn.v-btn--has-bg{
  background: transparent;
}
span{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}
h3{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #475661;
}
h4{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #9CABB5;
}
h5{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.mainDiv{
  max-width: 630px;
}
</style>