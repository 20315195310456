<template>
  <view-company-layout>
    <template #header>
      <Header >-->
        <template v-slot:right-header-component>
          <ProfileDropDown />
        </template>
      </Header>
    </template>
    <template #dashboard-content-header>
      <div id="background" class="tw-h-32 mt-3 tw-w-full tw-bg-no-repeat" >
        <div class="background_cover_image">
          <v-img :src="getCompanyProfile.cover_image" alt="cover_image" width="100%"/>
        </div>
        <div class="tw-pt-3 tw-hidden md:tw-block tw-pl-4 lg:tw-pl-20">
          <button id="back" @click="$router.push(backRoutee)" class="backBtn pa-2  tw-flex align-center">
            <vue-feather size="16" type="arrow-left" style="color: #475661"/>
            <h6 class="ml-1">Back</h6></button>
        </div>
      </div>
    </template>
    <template #dashboard-company-image>
      <div class="mar lg:tw-pl-20 tw-px-4">
        <!--      <div v-if="getCompanyProfile.company_logo">-->
        <!--      <span v-if="getCompanyProfile.company_logo && getCompanyProfile.company_logo.startsWith('https')">-->
        <!--        <img style="border-radius: 3px;" width="90" height="90" :src="getCompanyProfile.company_logo">-->
        <!--      </span>-->
        <!--      </div>-->
        <div>
          <div v-if="getCompanyProfile.company_logo">
            <img style="background: #ffffff" alt="award-image" class="rounded avatar img" width="70" height="70"
                 :src="getCompanyProfile.company_logo">
          </div>
          <div v-else>
            <v-avatar id="avatar" class="white--text avatar tile rounded" style="border-radius: 3px;border: 3px solid #ffffff" size="70" color="#8F47F4"><h3>
              {{ initials(getCompanyProfile.company_name) }}</h3></v-avatar>
          </div>
        </div>

      </div>
    </template>

    <template #dashboard-sideNav>
      <div class="tw-mt-6">
        <view-company-side-content/>
      </div>
    </template>
    <template #dashboard-content>
      <view-component-tabs/>
    </template>

  </view-company-layout>
</template>

<script>
import ViewCompanyLayout from "@/components/viewCompanyProfile/reuseables/ViewCompanyLayout";
import Header from "@/components/reusables/Header";
import ViewComponentTabs from "@/components/viewCompanyProfile/components/ViewComponentTabs";
import ProfileDropDown from "@/components/reusables/ProfileDropDown";
import ViewCompanySideContent from "@/components/viewCompanyProfile/reuseables/ViewCompanySideContent";
import {mapGetters} from "vuex";
export default {
  name: "ViewCompany",
  components: {ViewCompanySideContent, ProfileDropDown, ViewComponentTabs, Header, ViewCompanyLayout},
  beforeRouteEnter(to, from, next) {
    let backRoute = from.fullPath
    return next(vm => {
      vm.$data.backRoute = backRoute
      let route = sessionStorage.getItem("backRoute")
      if (!route) {
        sessionStorage.setItem("backRoute", backRoute);
      }
    });
  },
  computed:{
      ...mapGetters('companyProfile', ['getCompanyProfile']),
      getUserDetails() {
        return JSON.parse(sessionStorage.getItem('userData'))
      },
    backRoutee(){
      return sessionStorage.getItem("backRoute");
    }
  },
  methods:{
    initials(name) {
      if (name) {
        let firstName = name.split(" ");
        const initial = firstName.shift().charAt(0);
        return initial.toUpperCase();
      }
    },
  }
}
</script>

<style scoped lang="scss">
.backBtn{
  background: rgba(251, 251, 251, 0.6);
  border-radius: 6px;
  outline: none;
  position: absolute;
  z-index: 2;
}
.avatar {
  border: 2.14286px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4.28571px;
}

.avatar h3{
  font-style: normal;
  font-weight: bold;
  font-size: 25.7143px;
  line-height: 26px;
  color: #FFFFFF;
}
.tile {
  background: #8F47F4;
}

#background {
  position: relative;
  overflow: hidden;

  & .background_cover_image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    opacity: 0.8;
  }
}

/*.background--image {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  left: 0;*/
/*  bottom: 0;*/

/*}*/
</style>