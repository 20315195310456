<template>
  <svg width="126" height="140" viewBox="0 0 126 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_37566_216758)">
      <path d="M5.85283 30.123C4.82954 24.076 8.90212 18.3443 14.9492 17.321L91.5936 4.35116C97.6407 3.32787 103.372 7.40046 104.396 13.4475L120.145 106.516C121.168 112.563 117.095 118.294 111.048 119.318L34.4039 132.288C28.3569 133.311 22.6252 129.238 21.6019 123.191L5.85283 30.123Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter1_d_37566_216758)">
      <path d="M120.147 30.123C121.17 24.076 117.098 18.3443 111.051 17.321L34.4064 4.35116C28.3593 3.32787 22.6277 7.40046 21.6044 13.4475L5.85529 106.516C4.832 112.563 8.90459 118.294 14.9517 119.318L91.5961 132.288C97.6431 133.311 103.375 129.238 104.398 123.191L120.147 30.123Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter2_d_37566_216758)">
      <path d="M13.0273 21.1263C13.0273 14.9933 17.9992 10.0215 24.1322 10.0215H101.866C107.999 10.0215 112.971 14.9933 112.971 21.1263V115.518C112.971 121.651 107.999 126.623 101.866 126.623H24.1322C17.9991 126.623 13.0273 121.651 13.0273 115.518V21.1263Z" fill="#F7FCFF"/>
    </g>
    <g clip-path="url(#clip0_37566_216758)">
      <path d="M63.0019 72.4862C68.3683 72.4862 72.7187 68.1359 72.7187 62.7695C72.7187 57.4031 68.3683 53.0527 63.0019 53.0527C57.6355 53.0527 53.2852 57.4031 53.2852 62.7695C53.2852 68.1359 57.6355 72.4862 63.0019 72.4862Z" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M57.7402 70.9436L56.0605 83.5892L63.0011 79.4249L69.9416 83.5892L68.262 70.9297" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_d_37566_216758" x="0.142881" y="0.029036" width="125.712" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216758"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216758" result="shape"/>
      </filter>
      <filter id="filter1_d_37566_216758" x="0.144835" y="0.029036" width="125.712" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216758"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216758" result="shape"/>
      </filter>
      <filter id="filter2_d_37566_216758" x="7.47491" y="5.85716" width="111.048" height="127.706" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37566_216758"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37566_216758" result="shape"/>
      </filter>
      <clipPath id="clip0_37566_216758">
        <rect width="33.3146" height="33.3146" fill="white" transform="translate(46.3438 51.6641)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "AwardIcon"
}
</script>

<style scoped>

</style>