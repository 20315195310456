<template>
  <section class="tw-pl-0 md:tw-pl-8">
    <div v-if="isLoading">
      <Loader loading/>
    </div>
    <div v-else>
    <div v-if="!$route.query.write" class="tabbs">
      <v-tabs show-arrows v-model="active_tab">
        <v-tab v-for="(tab, i) in tabs" :key="i" @click="switchTab(tab.name)">
          <div class="text-capitalize"  >
            <span>{{tab.name}}</span>
          </div>
        </v-tab>
      </v-tabs>
    </div>
    <div class="tw-pt-4 tw-px-4 md:tw-px-0">
      <keep-alive>
        <component v-bind:is="returnMainContent()"></component>
      </keep-alive>
    </div>
    </div>
  </section>
</template>


<script>


import Description from "@/components/viewCompanyProfile/components/Description";
import Awards from "@/components/viewCompanyProfile/components/Awards";
import FAQs from "@/components/viewCompanyProfile/components/FAQs";
import Media from "@/components/viewCompanyProfile/components/Media";
import Reviews from "@/components/viewCompanyProfile/components/Reviews";
import AllJobs from "@/components/viewCompanyProfile/components/AllJobs";
import Loader from "@/components/reusables/Loader";
import {mapGetters} from "vuex";
export default {
  name: "ViewComponentTabs",
  components: {Loader, AllJobs, Media, FAQs, Awards, Description},
  data(){
    return{
      active_tab: "",
      mode: "",
      tabs: [
        {
          id: 0,
          name: "Description"
        },
        {
          id: 1,
          name: "Jobs"
        },
        {
          id: 2,
          name: "Media"
        },
        {
          id: 3,
          name: "Reviews"
        },
        {
          id: 4,
          name: "Awards"
        },
        {
          id: 5,
          name: "FAQs"
        },
      ],
    }
  },
  methods:{
    switchTab(name) {
      this.mode = name;
      if(name === 'Description') {
        this.$router.push({query:{site_name:this.$route.query.site_name}, params:{tab: "description"}})
      } else if(name === 'Jobs') {
        this.$router.push({query:{site_name:this.$route.query.site_name}, params:{tab: "jobs"}})
      }else if(name === 'Media') {
        this.$router.push({query:{site_name:this.$route.query.site_name}, params:{tab: "media"}})
      }else if(name === 'Reviews') {
        this.$router.push({query:{site_name:this.$route.query.site_name}, params:{tab: "reviews"}})
      }else if(name === 'Awards') {
        this.$router.push({query:{site_name:this.$route.query.site_name}, params:{tab: "awards"}})
      }else if(name === 'FAQs') {
        this.$router.push({query:{site_name:this.$route.query.site_name},params:{tab: "faqs"}})
      } else {
        this.$router.push({query:{site_name:this.$route.query.site_name}, params:{tab: "description"}})
      }
    },
    returnMainContent() {
      let component = undefined
      // let tab = this.mode;
      if (this.$route.params.tab === "description") {
        this.active_tab = 0
        component = Description;
      }
      else if (this.$route.params.tab === "jobs") {
        this.active_tab = 1
        component =  AllJobs;
      }
      else if (this.$route.params.tab === "media") {
        this.active_tab = 2
        component = Media;
      }
      else if (this.$route.params.tab === "reviews") {
        this.active_tab = 3
        component = Reviews;
      }
      else if (this.$route.params.tab === "awards") {
        this.active_tab = 4
        component =  Awards;
      }
      else if(this.$route.params.tab === "faqs") {
        this.active_tab = 5
        component = FAQs;
      }
      else{
        this.active_tab = 0
        component = Description;
      }
      return component
    },
  },
  created() {
      this.$store.dispatch("companyProfile/getDescriptionAndMedia", this.$route.query.site_name)
  },
  computed:{
    ...mapGetters("companyProfile", ["isLoading", "companyDescriptionAndMedia"])
  }
}
</script>

<style scoped>
span{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #4F4F4F;
}
.tabbs ::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
  visibility: hidden;
}
</style>