<template>
  <svg width="126" height="140" viewBox="0 0 126 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_39102_234368)">
      <path d="M5.85283 30.623C4.82954 24.576 8.90212 18.8443 14.9492 17.821L91.5936 4.85116C97.6407 3.82787 103.372 7.90046 104.396 13.9475L120.145 107.016C121.168 113.063 117.095 118.794 111.048 119.818L34.4039 132.788C28.3569 133.811 22.6252 129.738 21.6019 123.691L5.85283 30.623Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter1_d_39102_234368)">
      <path d="M120.147 30.623C121.17 24.576 117.098 18.8443 111.051 17.821L34.4064 4.85116C28.3593 3.82787 22.6277 7.90046 21.6044 13.9475L5.8553 107.016C4.83201 113.063 8.90459 118.794 14.9517 119.818L91.5961 132.788C97.6431 133.811 103.375 129.738 104.398 123.691L120.147 30.623Z" fill="#F7FCFF"/>
    </g>
    <g filter="url(#filter2_d_39102_234368)">
      <path d="M13.0273 21.6263C13.0273 15.4933 17.9992 10.5215 24.1322 10.5215H101.866C107.999 10.5215 112.971 15.4933 112.971 21.6263V116.018C112.971 122.151 107.999 127.123 101.866 127.123H24.1322C17.9991 127.123 13.0273 122.151 13.0273 116.018V21.6263Z" fill="#F7FCFF"/>
    </g>
    <path d="M65.7776 54.9395H54.6727C53.9364 54.9395 53.2303 55.2319 52.7096 55.7526C52.189 56.2732 51.8965 56.9794 51.8965 57.7157V79.9254C51.8965 80.6617 52.189 81.3678 52.7096 81.8885C53.2303 82.4091 53.9364 82.7016 54.6727 82.7016H71.33C72.0663 82.7016 72.7724 82.4091 73.2931 81.8885C73.8137 81.3678 74.1062 80.6617 74.1062 79.9254V63.2681L65.7776 54.9395Z" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M68.5541 75.7617H57.4492" stroke="#008EEF" stroke-width="2.08216" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M68.5541 70.209H57.4492" stroke="#008EEF" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M60.2254 64.6562H58.8373H57.4492" stroke="#008EEF" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M65.7773 54.9395V63.2681H74.106" stroke="#008EEF" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <filter id="filter0_d_39102_234368" x="0.142881" y="0.529036" width="125.712" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_39102_234368"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_39102_234368" result="shape"/>
      </filter>
      <filter id="filter1_d_39102_234368" x="0.144835" y="0.529036" width="125.712" height="139.357" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_39102_234368"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_39102_234368" result="shape"/>
      </filter>
      <filter id="filter2_d_39102_234368" x="7.47491" y="6.35716" width="111.048" height="127.706" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.38811"/>
        <feGaussianBlur stdDeviation="2.77622"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.937255 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_39102_234368"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_39102_234368" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "FaqIcon"
}
</script>

<style scoped>

</style>