<template>
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5459 2.28113C14.0328 2.50852 13.4815 2.66216 12.902 2.7316C13.4999 2.3738 13.9473 1.81067 14.1606 1.14727C13.5988 1.48097 12.9839 1.71586 12.3427 1.84172C11.9115 1.38134 11.3404 1.07618 10.718 0.973645C10.0957 0.871105 9.45684 0.976913 8.90075 1.27464C8.34466 1.57237 7.90243 2.04536 7.6427 2.62018C7.38298 3.195 7.32029 3.83949 7.46438 4.45359C6.32603 4.39643 5.21242 4.10056 4.19582 3.58516C3.17922 3.06977 2.28234 2.34637 1.56341 1.46193C1.31759 1.88597 1.17624 2.37762 1.17624 2.90122C1.17597 3.37258 1.29204 3.83672 1.51417 4.25246C1.7363 4.6682 2.05761 5.02269 2.4496 5.28447C1.995 5.27 1.55043 5.14717 1.15289 4.92618V4.96306C1.15284 5.62416 1.38152 6.26492 1.80013 6.77661C2.21873 7.2883 2.80148 7.63941 3.44949 7.77035C3.02777 7.88448 2.58563 7.90129 2.15646 7.81952C2.33929 8.38836 2.69542 8.88579 3.175 9.24217C3.65459 9.59855 4.23361 9.79604 4.83101 9.80699C3.81689 10.6031 2.56445 11.0349 1.27518 11.033C1.0468 11.0331 0.818615 11.0198 0.591797 10.9931C1.90048 11.8345 3.42388 12.2811 4.97973 12.2794C10.2465 12.2794 13.1257 7.91723 13.1257 4.13402C13.1257 4.01111 13.1226 3.88697 13.1171 3.76406C13.6771 3.35905 14.1605 2.85752 14.5447 2.28297L14.5459 2.28113Z" fill="#475661"/>
  </svg>

</template>

<script>
export default {
  name: "TwitterIcon"
}
</script>

<style scoped>

</style>