<template>
<div>
  <v-dialog v-model="dialog" max-width="420" @click:outside="close" @keypress.esc="close">
    <v-card >
      <v-card-title>
        <div class="d-flex tw-w-full mb-5 justify-space-between align-center">
          <h3>Are you sure you want to submit?</h3>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="d-flex justify-center align-center">
        <h2>
          Once your review is posted, you cannot edit it, you can only delete it.

        </h2>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-5">
        <div class="d-flex">
          <BaseButton outlined button-text="No" class="mr-2" width="70" @click="close" depressed/>
          <BaseButton button-text="Yes" @click="confirmModal" depressed width="70"/>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
export default {
  name: "ConfirmReviewModal",
  components: {BaseButton},
  props:{
    dialog:Boolean
  },
  methods:{
    close() {
      this.$emit('close')
    },
    confirmModal(){
      this.$emit('click', 'submit')
      this.close()
    }
  }
}
</script>

<style scoped>
h3{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  word-break: normal;
  overflow: auto;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  /* Enum-Darkblue/1 */

  color: #001343;
}
h2{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  display: flex;
  align-items: center;

  /* Enum-Grey/2 */

  color: #475661;

}

</style>