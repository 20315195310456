<template>
  <div class="pa-6 mt-n4">
    <div>
      <h2 id="compname" class="compName text-capitalize">{{ getCompanyProfile.company_name || '- - -' }}</h2>
      <p id="type" class="industry tw-mr-1">{{ getCompanyProfile.industry || '- -' }}</p>
    </div>

    <div class="tw-flex tw-my-5">
      <div class="tw-mr-5">
        <h4 class="font-weight-bold ">{{ getCompanyProfile.followers }} </h4>
        <span id="followers" class="industry">Followers</span>
      </div>
      <div class="tw-mr-5">
        <h4 class="font-weight-bold">{{ getCompanyProfile.reviews }} </h4>
        <span id="reviews" class="industry">Reviews</span>
      </div>
      <div>
        <h4 class="font-weight-bold">{{ getCompanyProfile.jobs }} </h4>
        <span id="jobs" class="industry">Jobs</span>
      </div>
    </div>

    <div class="tw-pb-12">
      <BaseButton
          :loading="following"
          :button-text="getCompanyProfile.is_follower ? 'Following' : 'Follow'"
          class="text-capitalize"
          :depressed="getCompanyProfile.is_follower"
          :class="{'white--text': getCompanyProfile.is_follower}"
          :color="!getCompanyProfile.is_follower ? '#008eef' : '#475661' "
          :outlined="!getCompanyProfile.is_follower"
          height="45"
          @click="followUnfollow">
      </BaseButton>
    </div>

    <div class="lg:tw-block">
      <div class="tw-flex">
        <vue-feather size="19" type="map-pin" class="mr-2"/>
        <p id="location" class="numOfStaff">{{ getCompanyProfile.location || '- -' }}</p>
      </div>
      <div class="tw-flex py-1">
        <vue-feather size="19" type="users" class="mr-2"/>
        <p id="employee" class="numOfStaff">{{ getCompanyProfile.company_size || 'No' }} Employees</p>
      </div>
      <div class="tw-pb-5">
        <div
            v-if="!getCompanyProfile.facebook && !getCompanyProfile.instagram && !getCompanyProfile.linkedin && !getCompanyProfile.twitter">
          <p id="social" class="numOfStaff tw-mr-10 lg:tw-mr-0">No Social Links</p>
        </div>
        <div v-else class="tw-flex">
          <a target="_blank" :href="'https://' + getCompanyProfile.facebook"><FacebookIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.facebook"/></a>
          <a target="_blank" :href="'https://' + getCompanyProfile.instagram"><InstagramIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.instagram"/></a>
          <a target="_blank" :href="'https://' + getCompanyProfile.twitter"><TwitterIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.twitter"/></a>
          <a target="_blank" :href="'https://' + getCompanyProfile.linkedin"><LinkedInIcon class="tw-ml-2 tw-mt-1" v-if="getCompanyProfile.linkedin"/></a>
        </div>
      </div>
    </div>
    <a
        target="_blank"
        :href="'https://' + getCompanyProfile.website"
        id="website"
        v-if="getCompanyProfile.website"
    >
      <div class="tw-flex">
        <p id="http" class="numOfStaff tw-mr-1" style="color: #008EEF">
          Visit website
        </p>
        <ExternalLinkIcon style="color: #008EEF; height: 15px; width: 15px; margin-top: 3px"/>
      </div>
    </a>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ExternalLinkIcon} from 'vue-feather-icons'
import FacebookIcon from "@/components/reusables/FacebookIcon";
import InstagramIcon from "@/components/reusables/InstagramIcon";
import TwitterIcon from "@/components/reusables/TwitterIcon";
import LinkedInIcon from "@/components/reusables/LinkedInIcon";
import {
  followOrUnfollowCompany,
} from "@/services/api/APIService";
import BaseButton from "@/components/reusables/BaseButton";

export default {
  name: "ViewCompanySideContent",
  components: {BaseButton, ExternalLinkIcon, FacebookIcon,InstagramIcon, TwitterIcon, LinkedInIcon},
  data() {
    return {
      following: false,

    }
  },
  computed: {
    ...mapGetters('companyProfile', ['getCompanyProfile']),
    getUserDetails() {
      return JSON.parse(sessionStorage.getItem('userData'))
    }
  },
  methods: {
    async followUnfollow() {
      try {
        this.following = true
        const {data} = await followOrUnfollowCompany({site_name: this.$route.query.site_name})
        console.log(data)
        let info = {}
        info.site_name = this.$route.query.site_name
        info.email = this.getUserDetails.email
        await this.$store.dispatch('companyProfile/fetchCompanyProfileSummary', info)
      } catch (e) {
        this.$displaySnackbar({message: e.response.data.detail})
      } finally {
        this.following = false
      }
    },
  },
  created() {
    let data = {}
    data.site_name = this.$route.query.site_name
    data.email = this.getUserDetails.email
    this.$store.dispatch('companyProfile/fetchCompanyProfileSummary', data)
  }
}
</script>

<style scoped>
.compName {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #1E323F;
  margin-bottom: 0;
}

.industry {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.numOfStaff {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
}
</style>
